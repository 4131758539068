<template lang="pug">
.form-modal(v-if="showFormManager.thirdStep" @mousedown="goBack()")
  .content(@mousedown.stop="")
    .header.om-bottom-divider.p-3
      .header-inner.w-100
        .col-12.header-content
          .d-flex.align-items-center
            .mb-0.font-weight-bold(style="font-size: 18px") {{ $t('editField') }}
            om-button.ml-auto.p-1(
              icon="times"
              iconSize="1.715em"
              ghost
              iconOnly
              style="color: #b9bec6"
              @click="goBack()"
            )
    .d-flex.px-3.py-2.flex-column
      .row.py-2.px-4
        .types.col.header-content
          span {{ $t('name') }}
            input.simpleInput(:placeholder="$t('valuePlaceholder')" v-model="inputName")
          .alt-title.mt-1 {{ $t('customFieldNewTooltip') }}
      .row.py-2.px-4(v-if="isExcluded")
        .types.col.header-content
          span {{ $t('type') }}
            .sidebar-input-wrapper.flex-row-baseline
              span.epic-simple-input
                .select-arrow
                  i.fa.fa-angle-up(aria-hidden="true")
                select(v-model="inputType")
                  template(v-for="item in inputTypes")
                    option(:value="item") {{ $t(item) }}
      .row.pt-2.px-4.footer.mt-3
        .col-7.flex-grow(style="width: 50%")
          .alt-title {{ $t('inputModifyWarning') }}
        .col.flex-row.justify-content-end
          om-button(ghost @click="goBack()") {{ $t('cancel') }}
          om-button.ml-3(
            primary
            :disabled="isNameEmpty || loading"
            @click="saveEditedName"
            :loading="loading"
          ) {{ $t('save') }}
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import { apolloClient } from '@/apollo';
  import gql from 'graphql-tag';
  import formChooser from '@/editor/mixins/formChooser';
  import itemMixin from '../../../mixins/item';

  export default {
    mixins: [formChooser, itemMixin],

    data() {
      return {
        loading: false,
        inputNameVal: null,
        inputTypeVal: null,
      };
    },

    computed: {
      ...mapState([
        'showFormManager',
        'inputs',
        'selectedElementValue',
        'selectedElement',
        'templateSaveData',
      ]),

      isNameEmpty() {
        return this.inputName.length === 0;
      },

      inputName: {
        get() {
          return this.inputNameVal === null ? this.selectedElementValue.name : this.inputNameVal;
        },
        set(v) {
          this.inputNameVal = v;
        },
      },

      inputType: {
        get() {
          return this.inputTypeVal === null ? this.selectedElementValue.type : this.inputTypeVal;
        },
        set(v) {
          this.inputTypeVal = v;
        },
      },
    },

    watch: {
      'showFormManager.thirdStep': function (v) {
        if (v) {
          this.inputNameVal = null;
          this.inputTypeVal = null;
        }
      },
    },

    mounted() {
      if (this.showFormManager.thirdStep) {
        this.inputNameVal = null;
        this.inputTypeVal = null;
      }
    },

    methods: {
      ...mapMutations(['changeFormManagerVisibility', 'setStateAttr']),
      async saveEditedName() {
        if (!this.inputName.length) {
          return;
        }
        this.loading = true;

        const { _id } = this.selectedElementValue;
        const { type, value } = this.templateSaveData;

        const templateQuery = gql`mutation{updateMasterField(input: {_id: "${_id}", name: "${this.inputName}", type: "${this.inputType}", template: "${value}"})}`;
        const variantQuery = gql`mutation{updateField(input: {_id: "${_id}", name: "${this.inputName}", type: "${this.inputType}"})}`;

        try {
          await apolloClient.mutate({
            mutation: type === 'base' ? templateQuery : variantQuery,
          });

          const inputs = JSON.parse(JSON.stringify(this.inputs));

          const index = inputs.findIndex((i) => i.customId === this.selectedElementValue.customId);

          inputs[index].name = this.inputName;
          inputs[index].type = this.inputType;

          const customField = inputs[index];

          this.setStateAttr({ attr: `inputs`, value: inputs });

          this.$bus.$emit('switchInputElement', { customField });
          this.changeFormManagerVisibility({ hide: 'thirdStep' });
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      },
      goBack() {
        this.changeFormManagerVisibility({ hide: 'thirdStep' });
        this.changeFormManagerVisibility({ show: 'manageField' });
      },
    },
  };
</script>
<style lang="sass" scoped>
  .content
    min-height: auto !important
  .types
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 24px
    color: #505763
  .alt-title
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 16px
    color: #8F97A4
  .button-link
    color: black !important
  .custom
    font-family: Roboto
    font-style: normal
    font-weight: bold
</style>
